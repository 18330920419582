<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import type { CmsElementForm } from "@shopware-pwa/composables-next";
import { useCmsTranslations } from "@shopware-pwa/composables-next";
import { ApiClientError, type ApiError } from "@shopware/api-client";
import { useCmsElementConfig, useCmsUrlResolver, useNewsletter, useSalutations } from "#imports";
import { computed, reactive, ref } from "vue";
import { defu } from "defu";
import { mdiCheckCircleOutline } from "@mdi/js";
import { NuxtLink } from "#components";

const props = defineProps<{
  content: CmsElementForm;
}>();

type Translations = {
  form: {
    subscribeLabel: string;
    unsubscribeLabel: string;
    action: string;
    email: string;
    emailPlaceholder: string;
    salutation: string;
    salutationPlaceholder: string;
    firstName: string;
    firstNamePlaceholder: string;
    lastName: string;
    lastNamePlaceholder: string;
    privacy: string;
    privacyLabel: string;
    submit: string;
    newsletterBenefits: string;
  };
};

let translations: Translations = {
  form: {
    subscribeLabel: "Subscribe to newsletter",
    unsubscribeLabel: "Unsubscribe from newsletter",
    action: "Action",
    email: "Email address",
    emailPlaceholder: "Enter email address...",
    salutation: "Salutation",
    salutationPlaceholder: "Enter salutation...",
    firstName: "First name",
    firstNamePlaceholder: "Enter first name...",
    lastName: "Last name",
    lastNamePlaceholder: "Enter last name...",
    privacy: "Privacy",
    privacyLabel: "I have read the data protection information.",
    submit: "Submit",
    newsletterBenefits:
        "Be aware of upcoming sales and events.Receive gifts and special offers!",
  },
};

translations = defu(translations, useCmsTranslations()) as Translations;

const loading = ref<boolean>();
const formSent = ref<boolean>(false);
const errorMessages = ref<ApiError[]>([]);
const subscriptionOptions: {
  label: string;
  value: "subscribe" | "unsubscribe";
}[] = [
  {
    label: translations.form.subscribeLabel,
    value: "subscribe",
  },
  {
    label: translations.form.unsubscribeLabel,
    value: "unsubscribe",
  },
];
const { getConfigValue } = useCmsElementConfig(props.content);
const { newsletterSubscribe, newsletterUnsubscribe } = useNewsletter();

const getFormTitle = computed(() => getConfigValue("title"));
const getFormText = computed(() => getConfigValue("text"));

const privacyUrlConfig = getConfigValue("privacyUrl");
const { linkUrl, attr } = await useCmsUrlResolver(privacyUrlConfig)





const state = reactive({
  option: 'subscribe',
  email: "",
  checkbox: null,
});

const rules = computed(() => {
  let temp: any = {
    email: {
      required,
      email,
    },
    checkbox: {
      required,
      isTrue: (value: any) => value === true,
    },
  };

  return temp;
});

const $validator = useVuelidate(rules, state);
const invokeSubmit = async () => {
  $validator.value.$touch();
  const valid = await $validator.value.$validate();
  if (valid) {
    loading.value = true;
    try {
      if (state.option === "subscribe") {
        await newsletterSubscribe({
          ...state,
        });
      }
      formSent.value = true;
    } catch (e) {
      if (e instanceof ApiClientError) {
        errorMessages.value = e.details.errors;
      }
    } finally {
      loading.value = false;
    }
  }
};


</script>
<template>
  <v-form @submit.prevent="invokeSubmit">
    <div class="text-h2 mb-4 newsletter__title">
      {{ getFormTitle }}
    </div>
    <p class="mb-10 newsletter__text">
      {{ getFormText }}
    </p>
    <v-row no-gutters>
      <v-col cols="12" md class="pr-md-4" order-md="1">
        <v-text-field
            v-model="state.email"
            label="Ihre E-Mail Adresse"
            variant="solo"
            rounded="lg"
            required
            :error-messages="$validator.email.$errors.map(e => e.$message)"
            @blur="$validator.email.$touch"
            @input="$validator.email.$touch"
        />
      </v-col>
      <v-col cols="12" order-md="3">
        <v-checkbox
            required
            v-model="state.checkbox"
            :error-messages="$validator.checkbox.$errors.map(e => e.$message)"
            @blur="$validator.checkbox.$touch"
            @change="$validator.checkbox.$touch"
        >
          <template v-slot:label>
            <span>Ich akzeptiere die
              <component
                :is="privacyUrlConfig ? NuxtLink : 'span'"
                :class="{'text-decoration-underline': privacyUrlConfig }"
                target="_blank"
                :[attr]="linkUrl"> Datenschutzrichtlinien</component>.</span>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="12" md="auto" order-md="2">
        <v-btn variant="flat" color="primary" rounded size="x-large" class="text-body-1 font-weight-bold"
               type="submit">
            <span v-if="!formSent">
              Abonnieren
            </span>
          <span v-else>Abonniert</span>

          <template v-slot:prepend >
            <v-slide-x-reverse-transition>
              <v-icon :icon="mdiCheckCircleOutline" v-show="formSent"></v-icon>
            </v-slide-x-reverse-transition>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<style scoped>
.newsletter__title {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: 600;
}

.newsletter__text {
  font-size: 1.25rem;
}
</style>